import React, { ReactElement } from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import useDeviceWidth from 'components/hooks/deviceWidth/useDeviceWidth'
import Carousel from 'react-multi-carousel'

import 'react-multi-carousel/lib/styles.css'
import { IconName, IconSizeVariant, TextVariant } from 'tuix-webcomponents'

import { TuixIconComponent } from '../TuixWebcomponents/TuixIcon.wrapped'
import './Slider.scss'

type Props = {
    items: ReactElement[]
    itemsInMobile?: number
}

type ArrowGroupProps = {
    next?: () => void
    previous?: () => void
}

const CAROUSEL_BREAKPOINTS = {
    mobile: {
        breakpoint: { max: 640, min: 0 },
        items: 2,
        slidesToSlide: 2,
    },
}

const ArrowGroup = ({ next, previous }: ArrowGroupProps) => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <>
            <TuixIconComponent
                size={IconSizeVariant.MEDIUM}
                name={IconName.CHEVRON_LEFT}
                color={TextVariant.MAIN}
                className="arrow left"
                dark={isDarkMode}
                onClick={() => previous()}
            />
            <TuixIconComponent
                size={IconSizeVariant.MEDIUM}
                name={IconName.CHEVRON_RIGHT}
                color={TextVariant.MAIN}
                className="arrow right"
                dark={isDarkMode}
                onClick={() => next()}
            />
        </>
    )
}

export default ({ items }: Props): JSX.Element => {
    const { isMobile } = useDeviceWidth()

    return (
        <div className="containerCarousel">
            {isMobile ? (
                <Carousel
                    infinite
                    responsive={CAROUSEL_BREAKPOINTS}
                    renderButtonGroupOutside
                    itemClass="container-carousel-item"
                    arrows={false}
                    customButtonGroup={<ArrowGroup />}
                >
                    {items.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}
                </Carousel>
            ) : (
                items.map((item, index) => <div key={index}>{item}</div>)
            )}
        </div>
    )
}
