import * as React from 'react'

import IndexPage from 'pages/services/index'

import { Locale } from '../../utils/enums'

const IndexPageEn = (): JSX.Element => {
    return <IndexPage locale={Locale.en_US} />
}

export default IndexPageEn
