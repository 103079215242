import React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { TuixTextComponent } from 'components/TuixWebcomponents'

import './TextCard.scss'
import { TextVariant, TextSize } from 'tuix-webcomponents'
import clsx from 'clsx'

export enum TextSide {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface Props {
    title: string
    text: string
    lineDirection: TextSide
    className?: string
}

export default ({ title, text, className = '', lineDirection }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx('textCard_container', className, isDarkMode && 'dark')}>
            <TuixTextComponent
                size={TextSize.M}
                dark={isDarkMode}
                className="textCard_title"
                color={TextVariant.MAIN}
            >
                {title}
            </TuixTextComponent>
            <TuixTextComponent size={TextSize.Body1} dark={isDarkMode} className="textCard_text">
                {text}
            </TuixTextComponent>
            <div className={clsx('dotted_line_container', lineDirection)}>
                <hr className={clsx('dotted_line', isDarkMode && 'dark')}></hr>
            </div>
        </div>
    )
}
