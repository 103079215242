import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        mainImage: { nodes: nodesMainImage },
        section1: { nodes: nodesSection1 },
        section2: { nodes: nodesSection2 },
        allContentfulUxUiSection: { nodes: nodesUxUiSection },
        allContentfulInfoCardBulletPoints: { edges: nodesInfoCardBulletPointEdges },
        allContentfulTextAndIconCard: { edges: nodesTextsAndIconCardsEdges },
        commitHistoryLine: { nodes: nodesCommitHistoryLine },
        allContentfulSeo: { nodes: nodesMetaData },
    } = useStaticQuery(graphql`
        query QueryServices {
            mainImage: allContentfulMainImage(
                filter: { contentful_id: { eq: "5u3GSYXLuwv7ZcqFfzEq7u" } }
            ) {
                nodes {
                    subtitle
                    textVariantSubtitle
                    title
                    textVariantTitle
                    node_locale
                    textPosition
                    fontColor
                    buttonText
                    backgroundImage {
                        title
                        file {
                            url
                        }
                    }
                    backgroundImageDark {
                        title
                        file {
                            url
                        }
                    }
                }
            }

            section1: allContentfulSection(
                filter: { contentful_id: { eq: "7bJblCLZheXF9kThnwytQN" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }

            section2: allContentfulSection(
                filter: { contentful_id: { eq: "3sZvpphNcf58qLd8KX9Yj4" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    title
                }
            }

            allContentfulUxUiSection(filter: { contentful_id: { eq: "WPyRyEw8zoLl44zk9lF9a" } }) {
                nodes {
                    node_locale
                    title
                    description {
                        description
                    }
                    uxuiProcessImage {
                        file {
                            url
                        }
                    }
                    uxuiProcessImageDark {
                        file {
                            url
                        }
                    }
                    uxUiProcessImageTablet {
                        file {
                            url
                        }
                    }
                    uxUiProcessImageTabletDark {
                        file {
                            url
                        }
                    }
                    uxUiProcessImageMobile {
                        file {
                            url
                        }
                    }
                    uxUiProcessImageMobileDark {
                        file {
                            url
                        }
                    }
                    sentence
                    logos {
                        file {
                            url
                            fileName
                        }
                    }
                }
            }

            allContentfulInfoCardBulletPoints(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        infoCard {
                            text
                            iconUrl {
                                file {
                                    url
                                }
                            }
                            iconUrlDark {
                                file {
                                    url
                                }
                            }
                            orientation
                            node_locale
                        }
                        bulletPoint {
                            texts
                            node_locale
                        }
                        order
                        textSide
                        node_locale
                    }
                }
            }
            allContentfulTextAndIconCard(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        iconCard {
                            iconUrl {
                                file {
                                    url
                                }
                            }
                        }
                        textCard {
                            title
                            text {
                                text
                            }
                        }
                        textSide
                        bigIcon {
                            file {
                                url
                            }
                        }
                        bigIconDark {
                            file {
                                url
                            }
                        }
                        order
                        node_locale
                    }
                }
            }

            commitHistoryLine: allContentfulAsset(
                filter: { contentful_id: { eq: "4atHqIPiw9v0FdAdO7A5P6" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "4p8td4fRyr7mN4ny6PbrtU" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    image {
                        file {
                            url
                        }
                    }
                    node_locale
                }
            }
        }
    `)

    return {
        nodesMainImage,
        nodesSection1,
        nodesSection2,
        nodesUxUiSection,
        nodesInfoCardBulletPointEdges,
        nodesTextsAndIconCardsEdges,
        nodesCommitHistoryLine,
        nodesMetaData,
    }
}
