import clsx from 'clsx'
import IconCard from 'components/IconCard'
import TextCard from 'components/TextCard'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useIntersectionObserver } from 'components/hooks/intersectionOberver/useIntersectionObserverContext'
import React, { useEffect, useRef } from 'react'
import './TextAndIconCard.scss'

export enum TextSide {
    LEFT = 'left',
    RIGHT = 'right',
}
export interface Props {
    IconCardEntries: IIconCard
    TextCardEntries: ITextCard
    textSide: TextSide
    bigIcon: string
    bigIconDark: string
}

interface IconURL {
    url: string
}

interface File {
    file: IconURL
}

interface IIconCard {
    iconUrl: File
}

interface Text {
    text: string
}

interface ITextCard {
    text: Text
    title: string
}

export interface TextAndIconCardModel {
    iconCard: IIconCard
    textCard: ITextCard
    order: number
    textSide: TextSide
    bigIcon: File
    bigIconDark: File
}

export default ({
    IconCardEntries,
    TextCardEntries,
    textSide,
    bigIcon,
    bigIconDark,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    const directionClass = textSide === 'right' ? '' : 'howDoWeWork_containerReverse'
    const computedIconUrl = isDarkMode ? bigIconDark : bigIcon
    const iconRef = useRef(null)
    const textRef = useRef(null)
    const registerElement = useIntersectionObserver()

    useEffect(() => {
        registerElement(iconRef.current)
        registerElement(textRef.current)
    }, [iconRef, textRef])

    return (
        <div className={`iconAndTextCardSection_container howDoWeWork_container ${directionClass}`}>
            <img
                src={computedIconUrl}
                className="big_icon"
                alt="how do we work icon"
                ref={iconRef}
            />
            <div className="textIconLine_container" ref={textRef}>
                <div className={clsx('textIcon_container', isDarkMode && 'dark')}>
                    <IconCard
                        iconUrl={IconCardEntries.iconUrl.file.url}
                        className="textIcon_container__iconCard"
                    />
                    <TextCard
                        title={TextCardEntries.title}
                        text={TextCardEntries.text.text}
                        className="textIcon_container__textCard"
                        lineDirection={textSide}
                    />
                </div>
            </div>
        </div>
    )
}
