import React from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import './CurveSvg.scss'

export default (): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const circleColors = ['#FF9900', '#00B0B0', '#FF0000']

    return (
        <div className={clsx('curveSvg_mainContainer', isDarkMode && 'dark')}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="auto"
                viewBox="0 0 205 583"
                fill="none"
                preserveAspectRatio="none"
            >
                <path
                    d="M1 582.071V555.007C1 493.223 39.8076 438.097 97.9718 417.26L109.647 413.077C238.794 366.81 234.058 182.561 102.705 142.989V142.989C42.622 124.889 1.5 69.5525 1.5 6.80195V0.938782"
                    strokeWidth={2}
                />
            </svg>
            {circleColors.map((circleColor, index) => (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className={`curveSvg_circle${index + 1}`}
                    key={index}
                >
                    <circle cx="12" cy="12" r="11" fill={circleColor} strokeWidth={2} />
                </svg>
            ))}
        </div>
    )
}
